import './App.css';
import Dashboard from './Compontents/Dashboard';
import Orders from './Compontents/Orders'
import Login from './Compontents/Login';
import Cartitems from './Compontents/Cartitems';
import SideBar from './Compontents/SideBar';
import { ToastContainer} from 'react-toastify';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
function App() {
  return <>
   <BrowserRouter>
   <ToastContainer/>
   <Routes>
    <Route path='/Cart/:id' element={<Cartitems/>}/>
    <Route path='/' element={<Dashboard/>}/>
    <Route path='/orders' element={<Orders/>}/>
    <Route path='/Login' element={<Login/>}/>
   </Routes>
   </BrowserRouter>
  </>
}

export default App;
