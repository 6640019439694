import React, { useState, useEffect } from 'react'
import SideBar from './SideBar'
import Diversity1Icon from '@mui/icons-material/Diversity1';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useNavigate } from 'react-router-dom';
import { setAuthToken } from './setAuthToken';
import { CChart, CChartBar } from '@coreui/react-chartjs'
import axios from 'axios';
import axiosInstance, { axiosPrivate } from './axios';
import { DateRangePicker } from 'react-date-range';
import Skeleton from '@mui/material/Skeleton';
function Dashboard() {
    let [revenue, Setrevenue] = useState('Monthly')
    let [months, setmonth] = useState([])
    let [filter, setFilter] = useState(false)
    let [Orders, SetOrders] = useState([])
    let [states, setstate] = useState([])
    let [neworders, setneworders] = useState(0)
    let [Customers, setCustomer] = useState([])
    let [Total, setTotal] = useState(0)
    let [Startdate, setStartDate] = useState('')
    let [endDate, setEndDate] = useState('')
    let [loading, setloading] = useState(false)

    let Handlefilter = () => {
        setFilter(!filter)
    }

    const handleSelect = (date) => {
        console.log(date.selection.endDate)
        setStartDate(date.selection.startDate)
        setEndDate(date.selection.endDate)
    };

    const selectionRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    let navigate = useNavigate()

    let renew = async () => {
        try {
            let data = sessionStorage.getItem("User_details")
            let User_details = JSON.parse(data)
            let token = sessionStorage.getItem("refreshToken")
            let res = await axios.post(`https://api.ayurai.in/auth/renew`, {
                email: User_details.Email,
                token: token
            })
            if (res.status === 200) {
                sessionStorage.setItem('AccessToken', res.data.access_token)
                getAllOrders()
                getDashboard()
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    let getAllOrders = async () => {
        try {
            let companydetails = localStorage.getItem('companyid')
            let companyid = JSON.parse(companydetails)
            let res = await axios.post(`https://api.shop.ayurai.in/getOrdersbycompany`, {
                companyid: companyid
            })
            if (res.status === 200) {
                SetOrders(res.data)
                setCustomer(res.data.filter((item, index) => item.UID !== index).map((e) => {
                    return e
                }))
                let total = res.data.reduce((a, b) => {
                    return a + b.Data.total;
                }, 0)
                setTotal(total)
                setneworders(res.data.filter((e) => {
                    if (e.Data.orderstatus === 'placed') {
                        return e
                    }
                }).map((e) => { return e }))
                setloading(true)
            }
        }
        catch (error) {
            // renew()
            console.log(error)
        }
    }


    let getDashboard = async () => {
        try {
            let companydetails = localStorage.getItem('companyid')
            let companyid = JSON.parse(companydetails)
            let res = await axiosPrivate.get(`https://api.ayurai.in/api/data/getDashboarddetails/${companyid}`)
            if (res.status === 200) {
                setmonth(res.data.noOfmonths)
                setstate(res.data.noOfstates)
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getAllOrders();
        // getDashboard();
    }, [])

    return <>
        <SideBar />
        <div className="p-4 sm:ml-64">
            <div className="p-4 rounded-lg dark:border-gray-700 mt-14">
                <div className='Main-contents'>
                    <div className='revenue-filter'>
                        <button onClick={Handlefilter}>Apply filter</button>
                        {filter && <DateRangePicker className='date-picker'
                            ranges={[selectionRange]}
                            onChange={handleSelect}
                        />}
                        {/* <select onChange={(e)=>Setrevenue(e.target.value)}>
                        <option value='Monthly'>Last Month</option>
                        <option value='Weekly'>Last Week</option>
                        <option value='Today'>Today</option>
                        <option value='Yesterday'>Yesterday</option>
                        <option value='Quarterly'>Last 90 days</option>
                        <option value='Halfyearly'>Last 6 months</option>
                        <option value='Yearly'>Last 12 months</option>
                    </select> */}
                    </div>
                    <div className='Cards'>
                        {loading ? <>
                            <div className='card' onClick={() => { navigate('/orders') }}>
                                <div className='Icons'>
                                    <ShoppingCartIcon style={{ color: "green" }} />
                                </div>

                                <div className='card-details' id='order'>
                                    <p>{Orders.length}</p>
                                    <p>Orders</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='Icons' style={{ backgroundColor: "#89CFF0" }}>
                                    <CurrencyRupeeIcon style={{ color: "blue" }} />
                                </div>
                                <div className='card-details'>
                                    <p id="total">{Total}</p>
                                    <p>Revenue</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='Icons'>
                                    <LocalPharmacyIcon style={{ color: "green" }} />
                                </div>
                                <div className='card-details'>
                                    <p>{neworders.length}</p>
                                    <p>New Orders</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='Icons' style={{ backgroundColor: "#89CFF0" }}>
                                    <Diversity1Icon style={{ color: "blue" }} />
                                </div>
                                <div className='card-details'>
                                    <p>{Customers.length}</p>
                                    <p>Customers</p>
                                </div>

                            </div></> :
                            <div className='Cards'>
                                <Skeleton animation="wave" width={"18rem"} height={'10rem'} style={{ margin: '2rem', borderRadius: '8px' }} />
                                <Skeleton animation="wave" width={'18rem'} height={'10rem'} style={{ margin: '2rem', borderRadius: '8px' }} />
                                <Skeleton animation="wave" width={'18rem'} height={'10rem'} style={{ margin: '2rem', borderRadius: '8px' }} />
                                <Skeleton animation="wave" width={'18rem'} height={'10rem'} style={{ margin: '2rem', borderRadius: '8px' }} />
                            </div>
                        }
                    </div>
                    <div className='Sales_report'>
                        <p>Sales Report </p>
                        <div className='charts'>

                            <CChart className='chart'
                                type="bar"
                                data={{
                                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                                    datasets: [
                                        {
                                            label: 'Orders',
                                            backgroundColor: '#f87979',
                                            data: [months.Jan, months.Feb, months.Mar, months.Apr, months.May, months.June],
                                        },
                                    ],
                                }}
                                labels="months"
                            />
                            <CChart className='chart'
                                type="pie"
                                data={{
                                    labels: ['Tamil Nadu', 'Karnataka', 'Andhra', 'Telagana', 'Kerala'],
                                    datasets: [
                                        {
                                            backgroundColor: ['#0d98ba', '#F7B7A3', 'skyblue', '#90EE90', '#C70039'],
                                            data: [states.TamilNadu, states.Karnataka, states.andhra, states.telugana, states.kerala],
                                        },
                                    ],
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Dashboard