import React from 'react'
import SideBar from './SideBar'
import Table from '@mui/material/Table';
import { useState, useEffect } from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { SearchOutlined } from '@mui/icons-material'
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import { setAuthToken } from './setAuthToken';
import { useNavigate } from 'react-router-dom'
import axiosInstance, { axiosPrivate } from './axios';
import axios from 'axios';
function Orders() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [Search, setsearch] = useState('')
  const [Orders, SetOrders] = useState([])
  let Navigate = useNavigate()

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // let renew = async () => {
  //   try {
  //     let data = sessionStorage.getItem("User_details")
  //     let User_details = JSON.parse(data)
  //     let token = sessionStorage.getItem("refreshToken")
  //     let res = await axios.post(`http://13.200.57.123/auth/renew`, {
  //       email: User_details.Email,
  //       token: token
  //     })

  //     if (res.status === 200) {
  //       sessionStorage.setItem('AccessToken', res.data.access_token)
  //     }

  //   }

  //   catch (error) {
  //     console.log(error)
  //   }

  // }
  let getAllOrders = async () => {
    try {
      let companydetails = localStorage.getItem('companyid')
      let companyid = JSON.parse(companydetails)
      let res = await axios.post(`https://api.shop.ayurai.in/getOrdersbycompany`, {
        companyid: companyid
      })
      if (res.status === 200) {
        SetOrders(res.data)

      }
    }
    catch (error) {
      // renew()
      console.log(error)
    }
  }
  useEffect(() => {
    getAllOrders();
  }, [])

  function sortDateTime(dateTimeStr) {
    const [date, time] = dateTimeStr.split(' ');
    const [day, month, year] = date.split('/');
    const [hours, minutes, seconds] = time.split(':');
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:${seconds}`);
  }

  return <>
    <SideBar />
    <div className="p-4 sm:ml-64">
      <div className="p-4 rounded-lg dark:border-gray-700 mt-14">
        <h1 className='Order_title'>Orders</h1>
        <div className='Search-bar'>
          <div className='search-box'>
            <SearchOutlined />
            <input type="text" placeholder="Search" onChange={(e) => { setsearch(e.target.value) }} />

          </div>
        </div>
        <TableContainer sx={{ maxHeight: 440 }} className='Table mt-5' >
          <Table stickyHeader aria-label="sticky table" >
            <TableHead>
              <TableRow>

                <TableCell className='Table-header'
                  style={{ minWidth: "100px" }}

                >
                  Order id
                </TableCell>
                <TableCell className='Table-header'
                  align={"center"}
                  style={{ minWidth: "100px" }}

                >
                  Phone
                </TableCell>
                <TableCell className='Table-header'
                  align={"center"}
                  style={{ minWidth: "100px" }}

                >
                  Email
                </TableCell>
                <TableCell className='Table-header'
                  align={"center"}
                  style={{ minWidth: "100px" }}

                >
                  Date
                </TableCell>
                <TableCell className='Table-header'
                  align={"center"}
                  style={{ minWidth: "100px" }}

                >
                  Order Status
                </TableCell>
                <TableCell className='Table-header'
                  align={"center"}
                  style={{ minWidth: "100px" }}

                >
                  Total
                </TableCell>
                <TableCell className='Table-header'
                  align={"center"}
                  style={{ minWidth: "100px" }}

                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Orders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort((a, b) =>
                  sortDateTime(b.Data.dateandtime) - sortDateTime(a.Data.dateandtime)).filter((s) => {
                    if (Search === "") {
                      return true;
                    }
                    else if (s.Data.orderid.toLowerCase().includes(Search.toLowerCase())) {
                      return s;
                    }
                  })
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>


                      <TableCell >
                        {row?.Data?.Orderid}
                      </TableCell>
                      <TableCell align={"center"}>
                        {row.Data.phone}
                      </TableCell>
                      <TableCell align={"center"}>
                        {row.Data.email}
                      </TableCell>
                      <TableCell align={"center"}>
                        {row.Data.dateandtime}
                      </TableCell>
                      <TableCell align={"center"}>

                        <div style={row.Data.OrderStatus === 'cancelled' ? { color: "red", padding: '10px', backgroundColor: "#FFCCCB", borderRadius: '8px' } : row.Data.OrderStatus === 'received' ? { color: "yellow", backgroundColor: '#FFFFE0', borderRadius: '8px', padding: '10px' } : row.Data.OrderStatus === 'delivered' ? { color: 'green', backgroundColor: 'lightcyan', borderRadius: '8px', padding: '10px' } : {
                          color: "green", padding: '10px', backgroundColor: "#FFFFE0", borderRadius: '8px'
                        }}>{row.Data.OrderStatus}</div>
                      </TableCell>
                      <TableCell>
                        <p style={{ textAlign: "center" }}>{row.Data.total}</p>
                      </TableCell>
                      <TableCell align={"center"}>
                        <div style={{ padding: "2px", color: "white", backgroundColor: "black", borderRadius: "6px", cursor: "pointer" }} onClick={() => { Navigate(`/Cart/${row.id}`) }}>View</div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TableFooter align="right">
            <TablePagination

              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={Orders.length}

              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableFooter>
        </TableContainer>
      </div>
    </div>
  </>
}

export default Orders