import axios from 'axios';

const BASE_URL = 'https://api.ayurai.in';

const axiosInstance = axios.create({
    baseURL: BASE_URL,
});

const axiosPrivateInstance = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
});

// Add logging for request interceptor
axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        console.error('Request error:', error);
        return Promise.reject(error);
    }
);

// Add logging for response interceptor
axiosInstance.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;

        // Log the error for diagnosis
        console.error('Response error:', error);

        // Check if the error is due to token expiration
        if (error.response && error.response.status === 500 || error?.response?.data?.error?.name == "TokenExpiredError" && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const User_details = JSON.parse(localStorage.getItem('User_details'));
                const token = localStorage.getItem('refreshToken');

                const response = await axios.post(`${BASE_URL}/auth/renew`, {
                    email: User_details.Email,
                    token: token
                });

                // Save the new token
                localStorage.setItem('token', response.data.access_token);

                // Update the authorization header
                originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;

                // Retry the original request with the new token
                return axiosInstance(originalRequest);
            } catch (refreshError) {
                console.error('Token refresh failed:', refreshError);
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('User_details');
                window.location.href = '/Login';
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

// Add logging for request interceptor in private instance
axiosPrivateInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        console.error('Request error:', error);
        return Promise.reject(error);
    }
);

// Add logging for response interceptor in private instance
axiosPrivateInstance.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;

        console.error('Response error:', error);

        if (error.response && error.response.status === 500 || error?.response?.data?.error?.name == "TokenExpiredError" && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const User_details = JSON.parse(localStorage.getItem('User_details'));
                const token = localStorage.getItem('refreshToken');

                const response = await axios.post(`${'https://api.auth.ayurai.in/'}Renew`, {
                    user: "",
                    token: token
                });


                localStorage.setItem('token', response.data.accessToken);

                originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;

                // Retry the original request with the new token
                return axiosPrivateInstance(originalRequest);
            } catch (refreshError) {
                console.error('Token refresh failed:', refreshError);
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('User_details');
                window.location.href = '/Login';
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
export const axiosPrivate = axiosPrivateInstance;
